body {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
}

.container {
  display: flex;
  flex-direction: row;
  width: 100vw; /* 75% of the viewport width */
  margin: 0 auto; /* Center the container */
}

.topMenu {
  position: fixed; /* fixed positioning */
  top: 0; /* align to the top of the viewport */
  left: 0; /* align to the left of the viewport */
  width: 100%; /* stretch across the width of the viewport */
  z-index: 1000; /* ensure it appears above other elements */
  height: 7vh
}

.topTags {
  flex: 20;
  height: 100vh; /* adjust for the height of .topMenu */
  overflow-y: auto;
  padding: 20px;
  background-color: #F7F5F3;
}

.results {
  flex: 80;
  height: 100vh; /* adjust for the height of .topMenu */
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #F1EDEA;
  
}


/* Hide scrollbar for Chrome, Safari, and Opera */
.topTags::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.topTags {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@media screen and (max-width: 1024px) {
  .topTags {
    display: none;
  }
  .Card {
    font-size: 18px; /* Increase the base font size */
  }
}


@media screen and (max-width: 767px) {
  .topTags {
    display: none;
  }
  .Card {
    font-size: 24px /* Increase the base font size */
  }
}


/* Define styles for screens smaller than 600px (mobile) */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }
  .topTags,
  .results {
    flex: 100%;
  }
}


